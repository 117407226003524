
@font-face {
  font-family: 'Montserrat', sans-serif;
 src: local("Montserrat-Medium"),
 url("./fonts/Montserrat-Medium.ttf") format("truetype");
 }
 
 // Fonts
 $font-family-base: 'Montserrat', sans-serif;
 $headings-font-family:'Montserrat', sans-serif;
 
 $theme-colors: (
   "primary": #252424,
   "secondary": #454652,
   "accent":#ffffff,
   "light": #f3f3f3,
   "gray":#d6dde5,
   "dark": #252424,
   "info": #009fdc,
   "success": #28a745,
   "warning": #ffc107,
   "danger": #dc3545, 
     
 );
 
 :root {
   --them-text: #f3f3f3;
   --accent-text: #FFFFFF;
   --them-background: #252424;
   --accent-background: #1d1c1c;
   --svg-color: #252424;
   background-color: var(--them-background);
   color: var(--them-text);
   .btn-switch {
     border-color: #ffffff !important;
     background-color: #131A25 !important;
     background-image:url(media/sun.svg); }
   }
 
 
 .dark {
   color-scheme: dark;
   --them-text: #f3f3f3;
   --accent-text: #FFFFFF;
   --them-background: #252424;
   --accent-background: #1d1c1c;
   --svg-color: #252424;
   background-color: var(--them-background);
   color: var(--them-text);
   .btn-switch {
     border-color: #ffffff !important;
     background-color: #131A25 !important;
     background-image:url(media/sun.svg); }
   }
 
 .light {
   color-scheme: light;
   --them-text: #252424;
   --accent-text: #000000;
   --them-background: #F3F3F3;
   --accent-background: #FFFFFF;
   --svg-color:#F3F3F3;
   background-color: var(--them-background);
   color: var(--them-text);
   .btn-switch {
     border-color: #252424 !important;
     background-color: #f3F3F3 !important;
     background-image:url(media/moon.svg);
   }
 }
 
 html, #root{
   background-color: var(--them-background);
   color: var(--them-text);
   .offcanvas { background-color:#000000;color:#F3F3F3};
   .offcanvas.nav-link{color:#F3F3F3}; .nav-link:hover{opacity:0.7};
   .popover {background-color:var(--them-background) !important; border:0.3px solid var(--them-text)!important}
  .popover-arrow::before { border-top-color: var(--them-text) !important;}
  .popover-arrow::after { border-top-color: var(--them-bacground) !important;}
  .legende {padding-left :12px; border-left:2px solid  var(--them-text);}
  .legendeD {padding-left :12px; border-left:2px solid  var(--them-text);}
  .navbar{background-color:var(--them-background);color: var(--them-text)}
  .navbar-brand, .btn-light, .list-group-item{color: var(--them-text)}
  .navbar-brand:hover, .btn-light:hover, .list-group-item:hover{color: var(--accent-text)}
  .card{background-color:var(--them-background)!important;box-shadow:.4vw .4vw .1vw rgba(13,18,25,.5);transition:all .25s ease-in-out}
  .card-footer,.card-header{background-color:transparent!important}
 }
 
 .tel{padding-left:157px}
 
 body {
   max-width: 100%;
   overflow-x: hidden;
   margin: 0;
  
 }
 
 .page {
   max-width: 100%;
   overflow-x: hidden;
 }
 
 .btn-switch {
   position: fixed;
   z-index:1040;
   width: 25px;
   height: 25px;
   bottom: 40px;
   left: 30px;
   display: flex;
   background-image:url(media/moon.svg);
   background-size: 15px;
   background-repeat: no-repeat;
   background-position: center center;
   transition: 0.5s;
 }
 
 .btn-switch:hover {
   opacity: 0.8;
 }
 .navbar-brand{transition: all 0.5s ease-in-out;}
 .navbar-brand:hover{transform: scale(1.2)}
 @media screen and (max-device-width:480px), screen and (max-width:480px) {
   #DAMlogo{height:30px}
 }
 
 
 $enable-negative-margins: true; 
 $progress-border-radius: 0;
 $progress-height: 0.5rem;
 $btn-border-radius: 0 !important;
 $badge-border-radius:0;
 $card-border-radius:0;
 $card-border-width:0px ;
 
 #svgComponent{
   border-radius: 50%;
 }
 .popover{border-radius:0!important}
 .header {
   border: 3px solid var(--them-background);
   background-clip: padding-box;
   border-radius: 50%;
   background-image: url("./media/Header.png");
   background-position: center center;
   background-repeat: no-repeat;
   background-size: contain;
 }
 
  #ligne {
     fill:none;
 stroke-miterlimit:10;
  stroke-dasharray: 1500;
   stroke-dashoffset: 1500;
   animation-delay: 1s;
 animation: dash 4s ease-in-out;
 stroke-width:54px;}
 @keyframes dash {
   from {
     stroke-dashoffset: 0;
   }
   to {
     stroke-dashoffset:1500;
   }
 }
 .offcanvas .btn-close{filter: invert(1);}
 .loupe { width:250px;height: 500px }
 .imgheader {position:absolute; left:10vh; top:180px}
 .svg-link img { transition: .25s ease-in-out;}
 .svg-link:hover img {
   transform: translateX(5px);}
 code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
     monospace;
 }
 
 svg {display:inline !important}
 a {text-decoration: none !important;}
 
 .Sect {padding-top: 120px;}
 .wrap-left{
   float: left;
   shape-margin:0px;
   shape-outside: inset(1% round 50%);
   margin-left: 0;
   margin-top: 20px;
   margin-right: 20px;
 }
 .wrap-right{
   float: right;
   shape-margin:0px;
   shape-outside: inset(1% round 50%);
   margin-right: 0;
   margin-top: 20px;
   margin-right: 20px;
 }
 #David {height:200px}
 @import "~bootstrap/scss/bootstrap";
 